<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      :heading="getTitle()"
    />
    <template v-if="error">
      <v-banner
        single-line
        elevation="10"
      >
        <v-icon
          slot="icon"
          color="warning"
          size="36"
        >
          mdi-wifi-strength-alert-outline
        </v-icon>
        <div>
          There was a problem communicating to the server!
          <div class="code">
            <strong>
              {{ error }}
            </strong>
          </div>
        </div>
      </v-banner>
    </template>
    <span
      class="font-weight-light subtitle-1"
    >
      <v-btn
        elevation="1"
        color="blue darken-1"
        dark
        @click="goBack"
      >
        <v-icon
          color="white"
        >
          mdi-keyboard-backspace
        </v-icon>
        Back
      </v-btn>
    </span>
    <template v-if="items.length > 0">
      <v-row>
        <v-col>
          <h2>
            {{ items.length }} Calls
          </h2>
        </v-col>

        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-col class="text-right">
              <download-excel
                :data="parsedItems"
                type="csv"
                :name="fileName"
              >
                <v-icon
                  large
                  color="green darken-2"
                  v-on="on"
                >
                  mdi-microsoft-excel
                </v-icon>
              </download-excel>
            </v-col>
          </template>
          <span>Download</span>
        </v-tooltip>
      </v-row>
    </template>
    <v-card>
      <date-range
        :start-date="startDate"
        :end-date="endDate"
        @changedStartDate="changedStartDate($event)"
        @changedEndDate="changedEndDate($event)"
      />
      <template v-if="allVendors && Array.isArray(allVendors)">
        <v-combobox
          v-model="selectedVendors"
          :items="allVendors"
          label="Select vendors"
          multiple
        />
      </template>
    </v-card>
    <v-row dense>
      <v-col class="text-right">
        <v-btn
          color="primary"
          @click="loadData()"
        >
          Update
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            class="striped_table"
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="50"
            :sort-by="['created_at']"
            :sort-desc="true"
          >
            <template #item.vehicle="{value, item}">
              <template v-if="item.vehicle != null">
                <vehicle-top-line :vehicle="item.vehicle" />
              </template>
            </template>
            <template #item.created_at="{value}">
              {{ formatIsoDate(value) }}
            </template>
            <template #item.to="{value}">
              {{ formatPhoneNumber(value) }}
            </template>
            <template #item.from="{value}">
              {{ formatPhoneNumber(value) }}
            </template>
            <template #item.recording_duration="{value, item}">
              <a
                :href="item.recording_url"
                target="_blank"
              >
                {{ fancyTimeFormat(value) }}
              </a>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  import CallService from '@/services/CallService.js'
  import VehicleTopLine from '@/components/VehicleTopLine.vue'
  import DateRange from '@/components/DateRange.vue'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { countColorsMixin } from '@/mixins/countColorsMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'
  import { format, parseISO } from 'date-fns'

  export default {
    name: 'Calls',
    components: {
      VehicleTopLine,
      DateRange,
    },
    mixins: [formatterMixin, countColorsMixin, navigationMixin],
    data () {
      return {
        format,
        parseISO,
        items: [],
        calls: [],
        stats: [],
        search: '',
        loading: true,
        error: '',
        expanded: [],
        startDate: null,
        endDate: null,
        selectedVendors: [],
        vendor: '',
        headers: [
          { text: 'From', value: 'from' },
          { text: 'To', value: 'to' },
          { text: 'Call Status', value: 'dial_call_status' },
          { text: 'Duration', value: 'recording_duration' },
          { text: 'Call Received', value: 'created_at' },
        ],
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      fileName () {
        return `${this.accountName
          .toLowerCase()
          .replace(' ', '_')}_calls.xls`
      },
      allVendors () {
        var vendors = [...new Set(this.items.map(h => h.vendor))]
        if (this.$route.params.vendor_id) {
          return vendors[0]
        } else {
          return vendors
        }
      },
      ...mapGetters(['accountId', 'accountName']),
    },
    watch: {
      accountId () {
        this.loadData()
      },
    },
    created () {
      this.startDate = this.defaultStartDate()
      this.endDate = this.defaultEndDate()
      this.loadData()
    },
    methods: {
      loadData: function () {
        this.items = []
        this.loading = true

        var data = {
          startDate: format(parseISO(this.startDate), 'yyyy-MM-dd'),
          endDate: format(parseISO(this.endDate), 'yyyy-MM-dd'),
          vendorId: this.$route.params.vendor_id,
        }

        CallService.getCalls(data)
          .then(response => {
            this.items = response.data.calls
            this.stats = response.data.stats
            this.vendor = response.data.vendor_name
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
      changedStartDate (date) {
        this.startDate = date
      },
      changedEndDate (date) {
        this.endDate = date
      },
      getTitle () {
        return this.vendor + ' Calls'
      },
    },
  }
</script>
